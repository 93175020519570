import React from "react"
import Img from "gatsby-image"

import { AnchorLink } from "gatsby-plugin-anchor-links"

const Service = ({ img, link, service, description }) => (
  <div class="service-offer d-flex flex-row">
    <div class="icon">
      <AnchorLink to={link + "#top"}>
        <Img fixed={img} alt={service} />
        <p class="more-link"></p>
      </AnchorLink>
    </div>
    <div class="desc">
      <AnchorLink to={link + "#top"}>
        <h2>{service}</h2>
        <p>{description}</p>
      </AnchorLink>
    </div>
  </div>
)
export default Service
