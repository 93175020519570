import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import "../static/css/card.css"
import Service from "./service"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      regularChuteCleaning: file(
        relativePath: { eq: "services/regular-chute-cleaning.png" }
      ) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chuteUnblocking: file(
        relativePath: { eq: "services/24-7-unblocking.png" }
      ) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      binCleaning: file(relativePath: { eq: "services/bin-cleaning.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      regularStrataCleaning: file(
        relativePath: { eq: "services/regular-strata-cleaning.png" }
      ) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      homeCleaning: file(relativePath: { eq: "services/home-cleaning.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      carparkCleaning: file(
        relativePath: { eq: "services/carpark-cleaning.png" }
      ) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <section id="services" class="card container mg-30">
      <div class="row pd-30">
        <div class="col-md-12 text-align-center">
          <h1>Professional cleaning services</h1>
          <h3>
            Keep your building fresh with our regular services and FREE
            inspection reporting
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <Service
            img={data.regularChuteCleaning.childImageSharp.fixed}
            link={"/chute-cleaning"}
            service="Regular Chute Cleaning"
            description="Our expert team will keep your chute, waste equipment, and garbage room smelling fresh."
          />
          <Service
            img={data.chuteUnblocking.childImageSharp.fixed}
            link={"/247-unblocking"}
            service="24/7 Chute Unblocking"
            description="South East Queensland wide emergency garbage chute blockage service."
          />
          <Service
            img={data.binCleaning.childImageSharp.fixed}
            link={"/bin-cleaning"}
            service="Bin Cleaning"
            description="We come to you and professionally clean your bin once-off or on a regular basis."
          />
        </div>
        <div class="col-lg-6">
          <Service
            img={data.regularStrataCleaning.childImageSharp.fixed}
            link={"/strata-cleaning"}
            service="Regular Strata Cleaning"
            description="Our professional team will clean and maintain your strata community areas."
          />
          <Service
            img={data.homeCleaning.childImageSharp.fixed}
            link={"/home-clean"}
            service="Home Clean"
            description="Moving in or out or simply want a fresh house? Our cleaning staff will professionally clean your house or apartment!"
          />
          <Service
            img={data.carparkCleaning.childImageSharp.fixed}
            link={"/carpark-clean"}
            service="Car Park Cleaning"
            description="We will use our power sweepers and high-pressure technology to remove residue from commercial or residential parking lots and facilities."
          />
        </div>
      </div>
      <div className="text-align-center">
        <AnchorLink className="primary-btn dark-btn" to="/#contact">
          BOOK NOW
        </AnchorLink>
      </div>
    </section>
  )
}

export default Services
