import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Offer from "./offer"

const Offers = () => {
  const data = useStaticQuery(graphql`
    query {
      price: file(relativePath: { eq: "offers/price.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      availability: file(relativePath: { eq: "services/24-7-unblocking.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      negotiation: file(relativePath: { eq: "offers/negotiation.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      quality: file(relativePath: { eq: "offers/quality.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      report: file(relativePath: { eq: "offers/report.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      customers: file(relativePath: { eq: "offers/customers.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <section id="offers" class="card container mg-30">
      <div class="row pd-30">
        <div class="col-md-12 text-align-center">
          <h2>Reasons to choose us!</h2>
          <h3>Your special offers.</h3>
        </div>
      </div>
      <div class=" row">
        <div class="col-lg-6">
          <Offer
            img={data.customers.childImageSharp.fixed}
            link=""
            title="Customer-Centric"
            desc="Our mission is to provide you with the services you need for
                  the best price. We go the extra mile so you remember us next
                  time. See below for how we do that."
          />
          <Offer
            img={data.report.childImageSharp.fixed}
            link=""
            title="You Receive FREE Inspection Reports"
            desc="Our experts inspect the condition of your site for free and
                  email you a inspection report before quoting and after each
                  service."
          />
          <Offer
            img={data.availability.childImageSharp.fixed}
            link=""
            title="Available 24/7"
            desc="Our on-call experts are available any time you want. Simply
                  call our emergency number to connect directly with our staff."
          />
        </div>
        <div class="col-lg-6">
          <Offer
            img={data.negotiation.childImageSharp.fixed}
            link=""
            title="Negotiable"
            desc="We negotiate the terms you want. Call us for a conversation
              about what you need."
          />
          <Offer
            img={data.quality.childImageSharp.fixed}
            link=""
            title="100% Success Guarantee"
            desc="We back our expert cleaners with a 100% satisfaction
              guarantee, or your money back!"
          />
          <Offer
            img={data.price.childImageSharp.fixed}
            link=""
            title="Competitive"
            desc="Someone offers a lower price? We'll match them without
              sacrificing quality. We also offer 2 FREE chute unblocks for regular clients"
          />
        </div>
        <div class="text-align-center" style={{ width: "100%" }}>
          <AnchorLink className="primary-btn dark-btn" to="/#contact">
            CONTACT US
          </AnchorLink>
        </div>
      </div>
    </section>
  )
}

export default Offers
