import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Services from "../components/services"
import Offers from "../components/offers"
import Testimonials from "../components/testimonials"

import Process from "../components/process"

const IndexPage = () => (
  <Layout style={{ height: "100vh" }}>
    <SEO
      description="We offer professional chute cleaning, unblocking and general cleaning services to Brisbane, the Gold Coast, and Sunshine Coast. Contact us for a custom job"
      lang="en"
      meta={""}
      title="Queensland Building Experts - ChuteOff Australia"
    />
    <Services />
    <Process />
    <Offers />
    <Testimonials />
    
  </Layout>
)

export default IndexPage
