import React from "react"
// import { Link } from "gatsby"
import Img from "gatsby-image"

const Offer = ({ img, title, desc, link }) => (
  <div class="single-offer d-flex flex-row pb-30">
    <div class="icon">
      <Img fixed={img} alt={desc} />
    </div>
    <div class="desc">
      {/* <Link to={link}> */}
      <h4>{title}</h4>
      {/* </Link> */}
      <p>{desc}</p>
    </div>
  </div>
)

export default Offer
